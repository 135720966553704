import axios from "axios";

export async function getDataApi(URL, access_token) {
  try {
    let response = await axios({
      method: "GET",
      url: URL,
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function postDataApi(URL, access_token, data) {
  //   console.log(URL, data, access_token);
  try {
    let response = await axios
      .post(URL, data, {
        method: "POST",
        url: URL,
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}

export async function putDataApi(URL, access_token, data) {
  try {
    let response = await axios
      .put(URL, data, {
        method: "PUT",
        url: URL,
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }
}

// export async function patchDataApi(URL, data, header) {
//     console.log(URL, header)
//     try {
//         let response = await axios.patch(URL, data, header)
//             .then(response => {
//                 console.log(response);
//                 return response;
//             })
//             .catch(error => error)
//         return response
//     } catch (error) {
//         return error;
//     }
// }

export async function deleteDataApi(URL, access_token, data) {
  try {
    let response = await axios
      .delete(URL, data, {
        method: "DELETE",
        url: URL,
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => error);
    return response;
  } catch (error) {
    return error;
  }

  // var config = {
  //   method: "delete",
  //   url: `http://promagcms.mobmaxime.com/API/PublisherGroup/PublisherGroup?id=${id}`,
  //   headers: {
  //     Authorization:
  //       "Bearer fsvqKLR-ExthGSx-FYjk-iY_NOpsJSy3DaJ911p51SRBnd1KlIJmsiPDJlIGW-WBfwezR3te2saDmtEyA_2-eWTDsm1XYOWluhoHlWGtfYSf9y00klrk7xeqBlQCspEGFlB3eFnTsr2y6DklH1KXL8DxEuzSV0177bh-7gybufAF6woIe4oglAKcbnebsnNKkVJj0eZGii3B4E3OZArWDgS9dKyj3IgAjV0v-O0C3-Pmjq4i5Ba7Uk2N6q_Hw-71DJvPMyOc8nTBmJpphgeT6BXPtNjj70a1e4b-KTrvsheshA-5THd87kWv5uJErcQjlFkRaQa5weL3uHOeY52exYBbtruFWCZIWWvo1Zb7_kH3G533teBFVxP6UC8ElSuQeYmLzfVHMU-ZlfTYb5L3MnTF79QraILQWlkRH_fxMwtYOp0Sg8844NM4LUeTK6HIpLHOzPHgpwcy1oIaY6-0yKKrjvWhopSMp29BibI8noPTJzonyEeYjb9rCLGkxTJ7nUnFi9rRy-tymykfLHKog91Aa0lm1ijF-31S7Cj1dD06ZVvyl4-cPKb-98ZCPTctc1PBiGL128OHAMTRR7ZKxeK6VMgNClNVdOqJl58S7TrU5x4zBesXnpW6fuT84ljXnNjE9IcUEcaWW1CGDxQQUA",
  //     "Content-Type": "application/x-www-form-urlencoded",
  //   },
  // };

  // let res = axios(config)
  //   .then(function (response) {
  //     //   console.log(response);
  //     return response;
  //   })
  //   .catch(function (error) {
  //     return error;
  //   });
  // return res;
}
