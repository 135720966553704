import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import { URLs } from "../../../utility/constants";
import { Button } from "react-bootstrap";
import '../common_style.scss';

export default function ProductDetails({
    history,
    productDetails,
    onBack,
    showProductVariantList
}) {

    useEffect(() => {
        history.push('/gear/product/details')
    }, [])

    return (
        <div className='card card-custom p-5'>
            <div className='d-flex mb-3'>
                <Button variant="btn btn-info" onClick={showProductVariantList}>
                    Product Variant
                </Button>
                <Button variant="btn btn-dark ml-2" onClick={onBack}>
                    Back
                </Button>
            </div>
            <div>
                <div className='d-flex flex-wrap'>
                    <div className='d-flex flex-column'>
                        <img className='product_img' src={`${URLs.awsS3ImageURL}${productDetails.image}`} />
                    </div>
                    <div className='ml-5 mt-4'>
                        <h3 className="card-title">
                            <div className="card-label font-weight-bolder text-dark">
                                {productDetails.name}
                            </div>
                            <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                Name
                            </div>
                        </h3>
                        <div className='d-flex'>
                            <div className='mr-5'>
                                <h3 className="card-title">
                                    <div className="card-label font-weight-bolder text-dark">
                                        {productDetails.CategoryName}
                                    </div>
                                    <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                        Category Name
                                    </div>
                                </h3>
                            </div>
                            <div className='ml-5'>
                                <h3 className="card-title">
                                    <div className="card-label font-weight-bolder text-dark">
                                        {productDetails.CompanyName}
                                    </div>
                                    <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                        Company Name
                                    </div>
                                </h3>
                            </div>
                        </div>
                        <div>
                            <h3 className="card-title">
                                <div className="card-label font-weight-bolder text-dark">
                                    {productDetails.description}
                                </div>
                                <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                    Description
                                </div>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
