import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import { URLs } from "../../../utility/constants";
import { Button } from "react-bootstrap";
import moment from 'moment';
import '../common_style.scss';

export default function ProductVariantDetails({
    history,
    productVariantDetails,
    onBack,
}) {

    useEffect(() => {
        history.push('/gear/product/product-variant/details')
        // console.log(productVariantDetails)
    }, [])

    return (
        <div className='card card-custom p-5'>
            <div className='d-flex mb-3'>
                <Button variant="btn btn-dark" onClick={onBack}>
                    Back
                </Button>
            </div>
            <div>
                <div className='d-flex flex-wrap'>
                    <div className='d-flex flex-column'>
                        <img className='product_img' src={`${URLs.awsS3ImageURL}${productVariantDetails.image}`} />
                    </div>
                    <div className='ml-5 mt-4'>
                        <h3 className="card-title">
                            <div className="card-label font-weight-bolder text-dark">
                                {productVariantDetails.name}
                            </div>
                            <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                Name
                            </div>
                        </h3>
                        <div className='d-flex'>
                            <div className='mr-5'>
                                <h3 className="card-title">
                                    <div className="card-label font-weight-bolder text-dark">
                                        {moment(productVariantDetails.createdAt).format('LL')}
                                    </div>
                                    <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                        Created At
                                    </div>
                                </h3>
                            </div>
                            <div className='ml-5'>
                                <h3 className="card-title">
                                    <div className="card-label font-weight-bolder text-dark">
                                        {moment(productVariantDetails.updatedAt).format('LL')}
                                    </div>
                                    <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                        Updated At
                                    </div>
                                </h3>
                            </div>
                        </div>
                        <div>
                            <h3 className="card-title">
                                <div className="card-label font-weight-bolder text-dark">
                                    {productVariantDetails.values}
                                </div>
                                <div className="text-muted mt-3 font-weight-bold font-size-sm">
                                    Values
                                </div>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
