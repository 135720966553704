import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import ReactQuill from "react-quill"; // Assuming you have ReactQuill installed
import "react-quill/dist/quill.snow.css";
import { getDataApi, putDataApi } from "../../../utility/apiCalling";
import { URLs } from "../../../utility/constants";
import { useSelector } from "react-redux";
import { toastMsgSuccess, toastMsgError } from "../../../utility/constants";
import { ToastContainer } from "react-toastify";

import "../common_style.scss";
export default function About_Us() {
  const state = useSelector((state) => state);
  const [pageOffset, setPageOffset] = useState(0);
  const [bodyText, setBodyText] = useState(""); // State to hold the content
  // const [bodyText, setBodyText] = useState(
  //   `FishFinder is on a mission… to inspire the fishing community to share their fishing experiences knowledge on a global community fishing platform to increase the advancement of the sport.\n\nWhat started as an idea to advance the sport’s fishing industry into the mobile tech space, has now evolved into a cross-device platform that answers the call for a technological update in the sport fishing industry. From beginners to avid professionals, FishAngler provides features for all anglers to capture fishing’s finest moments.`
  // );

  // useEffect(() => {
  //   // Load bodyText from local storage when component mounts
  //   const storedBodyText = localStorage.getItem("bodyText");
  //   if (storedBodyText) {
  //     setBodyText(storedBodyText);
  //   }
  // }, []);

  const fetchData = async () => {
    try {
      let res = await getDataApi(
        `${URLs.aboutusGetURL}?offset=${pageOffset}&limit=10`,
        state.auth.authToken
      );
      if (res.status === 200) {
        // console.log(res);
        // console.log(res.data.data.description);
        let res_data = res.data.data.description;
        setBodyText(res_data); // Assuming the response contains a bodyText field
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePost = async () => {
    try {
      let item = {
        description: bodyText,
      };

      let res = await putDataApi(
        URLs.aboutusPostURL,
        state.auth.authToken,
        item
      );
      console.log("Response:", res);

      if (res.status === 200) {
        fetchData();
        console.log("Data posted successfully");
        toastMsgSuccess("Successfully Added!");
      } else {
        console.log("Error message:", res.message);
        toastMsgError(res.message);
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
    // Logic to post the content (e.g., save it to a database)
    // console.log("Posting:", bodyText);
    // Save bodyText to local storage
    // localStorage.setItem("bodyText", bodyText);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Card>
        <div>
          <ReactQuill
            theme="snow"
            placeholder="Write something..."
            modules={About_Us.modules}
            formats={About_Us.formats}
            value={bodyText}
            onChange={(text) => setBodyText(text)}
            // readOnly={true} // Make the editor read-only
            // onChange={(e) => setBodyText(e)}
          />
        </div>

        <Button
          variant="success"
          style={{ width: "100px", marginTop: "50px", marginLeft: "45%" }}
          onClick={handlePost}
        >
          Submit
        </Button>
      </Card>
    </div>
  );
}

About_Us.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
About_Us.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
// import React from "react";

// export default function About_Us() {
//   return <div>About_Us</div>;
// }
