import React from "react";
import { Modal } from "react-bootstrap";

export default function DeleteModal({
    id,
    show,
    onHide,
    isDeleteModalShow,
    onDelete,
    headline,
    subHeading
}) {
    return (
        <Modal
            show={isDeleteModalShow}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {/*begin::Loading*/}
            {/* {isLoading && <ModalProgressBar />} */}
            {/*end::Loading*/}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{headline}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* {!isLoading && ( */}
                <span>{subHeading}</span>
                {/* )} */}
                {/* {isLoading && <span>Customer is deleting...</span>} */}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={onDelete}
                        className="btn btn-primary btn-elevate"
                    >
                        Delete
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
