const initState = {
  userList: [],
};

export const userReducer = (state = initState, action) => {
  if (action.type === "SET_USER_LIST") {
    state.userList = action.payload;
    return state;
  } else return state;
};
