import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import ReactQuill from "react-quill"; // Assuming you have ReactQuill installed
import "react-quill/dist/quill.snow.css";
import { toastMsgSuccess } from "../../../utility/constants";

export default function Support_Msg() {
  const [contactbodyText, setcontactbodyText] = useState(""); // State to hold the content

  useEffect(() => {
    // Load bodyText from local storage when component mounts
    const storedcontactBodyText = localStorage.getItem("contactbodyText");
    if (storedcontactBodyText) {
      setcontactbodyText(storedcontactBodyText);
    }
  }, []);

  const handlePost = () => {
    // Logic to post the content (e.g., save it to a database)
    console.log("Posting:", contactbodyText);
    toastMsgSuccess("Successfully Added!");
    // Save bodyText to local storage
    localStorage.setItem("contactbodyText", contactbodyText);
  };

  return (
    <div>
      <Card>
        <div>
          <ReactQuill
            theme="snow"
            placeholder="Write something..."
            modules={Support_Msg.modules}
            formats={Support_Msg.formats}
            value={contactbodyText}
            onChange={(text) => setcontactbodyText(text)}
          />
        </div>
        <Button variant="primary" onClick={handlePost}>
          Post
        </Button>
      </Card>
    </div>
  );
}

Support_Msg.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
Support_Msg.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
