import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
// import { Aboutus } from "./pages/Settings/Aboutus";
import Privacy_policy from "./pages/Settings/Privacy_policy";
import Support_Msg from "./pages/Settings/Support_Msg";
import Terms_and_Conditions from "./pages/Settings/Terms_and_Conditions";
import Copyright_policy from "./pages/Settings/Copyright_policy";

// import { Testpage } from "./pages/Test/Testpage";
import ProductDetails from "../_metronic/_partials/ProductDetails/ProductDetails";
import ProductVariantList from "./pages/Gear/sub_category_pages/ProductVariant/ProductVariantList";
import About_Us from "./pages/Settings/Aboutus";
import Feedback from "./pages/Feedback/Feedback";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const UserPage = lazy(() => import("./pages/User/User"));
const GearCategoryPage = lazy(() =>
  import("./pages/Gear/sub_category_pages/Category/Category")
);
const GearCompanyPage = lazy(() =>
  import("./pages/Gear/sub_category_pages/Company/Company")
);
const GearProductPage = lazy(() =>
  import("./pages/Gear/sub_category_pages/Product/Product")
);
const BaitPage = lazy(() => import("./pages/Bait/Bait"));
const SpeciesPage = lazy(() => import("./pages/Species/Species"));
const WatersPage = lazy(() => import("./pages/Waters/Waters"));
const UserPostPage = lazy(() => import("./pages/UserPost/UserPost"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        {/* new page added */}
        <Route path="/reported-content/user" component={UserPage} />
        <Route path="/reported-content/post" component={UserPostPage} />

        {/* feedback */}
        <Route path="/reported-content/feedback" component={Feedback} />

        <Route path="/gear/category" component={GearCategoryPage} />
        <Route path="/gear/company" component={GearCompanyPage} />
        <Route path="/gear/product" component={GearProductPage} />
        <Route path="/gear/product/details" component={ProductDetails} />
        <Route
          path="/gear/product/product-variant"
          component={ProductVariantList}
        />
        <Route path="/bait" component={BaitPage} />
        <Route path="/species" component={SpeciesPage} />
        <Route path="/waters" component={WatersPage} />
        {/* <Route path="/test" component={Testpage} /> */}
        <Route path="/settings/aboutus" component={About_Us} />
        <Route path="/settings/support_msg" component={Support_Msg} />
        <Route path="/settings/privacy_policy" component={Privacy_policy} />
        <Route
          path="/settings/terms_Conditions"
          component={Terms_and_Conditions}
        />
        <Route path="/settings/copyright_policy" component={Copyright_policy} />

        {/* new page added */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
