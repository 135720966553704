import axios from "axios";
import { getDataApi } from '../../../../utility/apiCalling'
import { URLs } from '../../../../utility/constants'
// export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/signin`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/token`;

export function login(email, password) {
  let cred = { email, password };
  return axios.post(LOGIN_URL, cred);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // return axios.get(ME_URL);
  // let reqBody =
  //   "username=kartik.solanki@mobmaxime.com&password=Welcome*1&grant_type=password";

  // let userCred = localStorage.getItem("userCred");
  // let val = JSON.parse(userCred);
  // return axios.post(LOGIN_URL, val);
  let access_token = localStorage.getItem("access_token")
  let userDetails = await getDataApi(URLs.getUserDetailsURL, access_token)
  // console.log(userDetails, access_token)
  return { user: userDetails.data.data }
}
