import React from "react";
import { Modal } from "react-bootstrap";
import { useFormik, Formik, Field } from "formik";
import ImageUploader from "react-images-upload";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import "../../../common_style.scss";

export default function AddEditProductVariant({
    onHideModal,
    isShowModal,
    initialValues,
    onSubmitForm,
    setImg,
    uploadedImg,
    isLoaderShow
}) {
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (value) => {
            onSubmitForm(value);
        },
    });
    return (
        <Modal
            size="lg"
            show={isShowModal}
            onHide={onHideModal}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <>
                <Formik
                    enableReinitialize={true}
                // initialValues={initialValues}
                // validationSchema={validationSchema}
                >
                    {({ getFieldProps, handleSubmit }) => (
                        <>
                            <Modal.Body className="overlay overlay-block cursor-default">
                                <form
                                    onSubmit={formik.handleSubmit}
                                    className="form form-label-right"
                                >
                                    <div className="form-group row d-flex justify-content-around">
                                        <div className="col-lg-4">
                                            <Field
                                                name="name"
                                                component={Input}
                                                type="text"
                                                placeholder="Name"
                                                label="Name"
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <ImageUploader
                                                buttonText="Upload Image"
                                                label="Please upload a image"
                                                onChange={(file) => setImg(file[0])}
                                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                                singleImage={true}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            {uploadedImg !== null && (
                                                <img
                                                    src={URL.createObjectURL(uploadedImg)}
                                                    className="uploaded_img"
                                                />
                                            )}
                                            {initialValues.image && uploadedImg === null ? (
                                                <img
                                                    src={initialValues.image.props.src}
                                                    className="uploaded_img"
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg-4">
                                            <Field
                                                name="values"
                                                component={Input}
                                                type="text"
                                                placeholder="Values"
                                                label="Values"
                                                onChange={formik.handleChange}
                                                value={formik.values.values}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <Modal.Footer>
                                        <button
                                            type="button"
                                            onClick={onHideModal}
                                            className="btn btn-light btn-elevate"
                                        >
                                            Cancel
                                        </button>
                                        <> </>
                                        <button type="submit" className="btn btn-primary font-weight-bold px-10">
                                            Save
                                            {isLoaderShow && <span className="ml-3 spinner spinner-white"></span>}
                                        </button>
                                    </Modal.Footer>
                                </form>
                            </Modal.Body>
                        </>
                    )}
                </Formik>
            </>
        </Modal>
    );
}
