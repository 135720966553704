import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Spinner,
  //   Pagination,
  Badge,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
// import paginationFactory from "react-bootstrap-table2-paginator";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../_metronic/_helpers";
import { getDataApi } from "../../../utility/apiCalling";
import {
  URLs,
  badgeContStyle,
  badgeStyle,
  toastMsgError,
  tableCellCharValidate,
} from "../../../utility/constants";
import { useSelector } from "react-redux";
// import Showfeedbackdetail from "./Showfeedbackdetail";
// import { useFormik, Formik, Field } from "formik";
// import {
//   toastMsgSuccess,
//   toastMsgError,
//   toastMsgWarn,
//   badgeContStyle,
//   badgeStyle,
//   tableCellCharValidate,
// } from "../../../utility/constants";

export default function Feedback({ className, history }) {
  const state = useSelector((state) => state);
  const [listData, setListData] = useState([]);
  const [isLoaderShow, setLoaderVisible] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [page, setPage] = useState(1);
  const defaultSorted = [{ dataField: "id", order: "asc" }];
  state.auth.authToken = localStorage.getItem("access_token");
  const [listDataNoValidate, setListDataNotValidate] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState("");
  // const { firstName, lastName } = selectedRowData;

  useEffect(() => {
    getList();
  }, [pageOffset]);

  const getList = async () => {
    try {
      let res = await getDataApi(
        `${URLs.feedbackGetURL}?offset=${pageOffset}&limit=10`,
        state.auth.authToken
      );
      if (res.status === 200) {
        let listArr = [];
        res.data.data.docs.forEach((item) => {
          console.log("item", item);
          listArr.push({
            _id: item._id,
            // type: tableCellCharValidate(item.feedbackType),
            // firstName: tableCellCharValidate(item.UserId.firstName),
            // lastName: tableCellCharValidate(item.UserId.lastName),
            // description: tableCellCharValidate(item.feedbackContent),
            type: item.feedbackType,
            firstName: item.UserId.firstName,
            lastName: item.UserId.lastName,
            description: item.feedbackContent,
          });
        });
        console.log("listArr", listArr);
        setListData(listArr);
        setLoaderVisible(false);
      } else {
        toastMsgError(res.message);
        setLoaderVisible(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoaderVisible(false);
    }
  };

  const renderActions = (_, row) => {
    return (
      <>
        <a
          title="Info"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={(e) => {
            e.stopPropagation();
            console.log(row);
            setSelectedRowData(row);
            setIsShowModal(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/info3.svg")}
            />
          </span>
        </a>
      </>
    );
  };

  const Desc_format = (_, row) => {
    let x = row?.description;
    return tableCellCharValidate(x);
  };
  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      hidden: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: Desc_format,
      // style: {
      //   width: "10px", // Set the desired width for the column
      //   wordWrap: "break-word",
      // },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: renderActions,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const goToNextPage = () => {
    setPageOffset(pageOffset + 10);
    setPage(page + 1);
  };

  const goToPrevPage = () => {
    if (pageOffset !== 0) {
      setPageOffset(pageOffset - 10);
      setPage(page - 1);
    }
  };

  const goToFirstPage = () => {
    setPageOffset(0);
    setPage(1);
  };

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Feedback
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {isLoaderShow && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15px 0",
              // wordWrap:"break-word"
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          keyField="_id"
          key="_id"
          data={listData}
          columns={columns}
          hover={true}
          defaultSorted={defaultSorted}
          //   rowEvents={{
          //     onClick: (e, row, rowIndex) => {
          //       getBaitDetails(row);
          //     },
          //   }}
        >
          <PleaseWaitMessage entities={listData} />
          <NoRecordsFoundMessage entities={listData} />
        </BootstrapTable>
      </div>

      {listData.length !== 0 && (
        <div className="mb-5" style={{ display: "flex", alignItems: "center" }}>
          {pageOffset !== 0 && (
            <Button variant="light mr-3" onClick={goToPrevPage}>
              Previous
            </Button>
          )}
          {listData.length === 10 ? (
            <Button variant="light" onClick={goToNextPage}>
              Next
            </Button>
          ) : null}
          <div style={badgeContStyle}>
            {page > 1 ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">Back to page 1</Tooltip>
                }
              >
                <Button onClick={goToFirstPage} variant="light">
                  <ExpandLessRoundedIcon />
                </Button>
              </OverlayTrigger>
            ) : null}
            <Badge variant="light ml-3" style={badgeStyle}>
              Page - {page}
            </Badge>
          </div>
        </div>
      )}

      {/* end::Body */}
      <ToastContainer />

      <Modal
        size="lg"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            {/* <input /> */}
            <div>
              <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="ml-5 mt-4">
                  <div className="d-flex">
                    <div className="mr-5">
                      <h3 className="card-title">
                        {selectedRowData ? (
                          <div className="card-label font-weight-bolder text-dark font-size-sm">
                            {selectedRowData.firstName}
                          </div>
                        ) : (
                          <div className="card-label font-weight-bolder text-dark">
                            No data avilable
                          </div>
                        )}

                        <div className="text-muted mt-3 font-weight-bold font-size-sm">
                          First Name
                        </div>
                      </h3>
                    </div>
                    <div className="ml-5">
                      <h3 className="card-title">
                        {selectedRowData ? (
                          <div className="card-label font-weight-bolder text-dark font-size-sm">
                            {selectedRowData.lastName}
                          </div>
                        ) : (
                          <div className="card-label font-weight-bolder text-dark">
                            No data avilable
                          </div>
                        )}

                        <div className="text-muted mt-3 font-weight-bold font-size-sm">
                          Last Name
                        </div>
                      </h3>
                    </div>

                    {/* <div className="ml-5">
                      <h3 className="card-title">
                        {selectedRowData ? (
                          <div className="card-label font-weight-bolder text-dark">
                            {selectedRowData.type}
                          </div>
                        ) : (
                          <div className="card-label font-weight-bolder text-dark">
                            No data avilable
                          </div>
                        )}
                        <div className="text-muted mt-3 font-weight-bold font-size-sm">
                          Type
                        </div>
                      </h3>
                    </div> */}
                  </div>
                  <div>
                    <h3 className="card-title">
                      {selectedRowData ? (
                        <div className="card-label font-weight-bolder text-dark font-size-sm">
                          {selectedRowData.type}
                        </div>
                      ) : (
                        <div className="card-label font-weight-bolder text-dark">
                          No data avilable
                        </div>
                      )}
                      <div className="text-muted mt-3 font-weight-bold font-size-sm">
                        Type
                      </div>
                    </h3>
                  </div>

                  <div>
                    <h3 className="card-title">
                      {selectedRowData ? (
                        <div className="card-label font-weight-bolder text-dark font-size-sm">
                          {selectedRowData.description}
                        </div>
                      ) : (
                        <div className="card-label font-weight-bolder text-dark">
                          No data avilable
                        </div>
                      )}
                      <div className="text-muted mt-3 font-weight-bold font-size-sm">
                        Description
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </div>
  );
}

// import React from "react";

// const feedbackData = [
//   {
//     id: 1,
//     type: "Bug Report",
//     details: "App crashes when clicking on button.",
//   },
//   {
//     id: 2,
//     type: "Feature Request",
//     details: "Add a search functionality to the app.",
//   },
//   {
//     id: 3,
//     type: "General Feedback",
//     details: "Overall app experience is great!",
//   },
// ];

// export const Feedback = () => {
//   return (
//     <div>
//       {/* <h2>Feedback Table</h2> */}
//       <table>
//         <thead>
//           <tr>
//             <th>#</th>
//             <th>Type</th>
//             <th>Details</th>
//           </tr>
//         </thead>
//         <tbody>
//           {feedbackData.map((item, index) => (
//             <tr key={index.id}>
//               <td>{index + 1}</td>
//               <td>{item.type}</td>
//               <td>{item.details}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };
