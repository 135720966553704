import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import {
    Spinner,
    Button,
    Badge,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    toAbsoluteUrl,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import AddEditProductVariant from "./AddEditProductVariant";
import DeleteModal from "../../../../../_metronic/_partials/DeleteModal/DeleteModal";
import ProductVariantDetails from "../../../../../_metronic/_partials/ProductVariantDetails/ProductVariantDetails";
import {
    getDataApi,
    postDataApi,
    deleteDataApi,
    putDataApi,
} from "../../../../../utility/apiCalling";
import { URLs, toastMsgSuccess, toastMsgError, toastMsgWarn, badgeContStyle, badgeStyle } from "../../../../../utility/constants";
import gearCsv from '../../../../../file_template/gear_templateV2.csv';

export default function ProductVariantList({
    className,
    history,
    onBack,
    productDetails
}) {

    const state = useSelector((state) => state);
    const [listData, setListData] = useState([]);
    const [isAddEditModalShow, setAddEditModalShow] = useState(false);
    const [selectedRowForCrud, setSelectedRow] = useState(null);
    const [crudAction, setCrudAction] = useState("");
    const [isDeleteModalShow, setDeleteModalShow] = useState(false);
    const [isLoaderShow, setLoaderVisible] = useState(false);
    const [isFileLoaderShow, setFileLoader] = useState(false);
    const [pageOffset, setPageOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [uploadedImg, setUploadImg] = useState(null);
    const [uploadedImgResURL, setUploadedImgResURL] = useState(null);
    const [addEditInitValues, setAddEditInitValues] = useState({
        _id: "",
        name: "",
        image: "",
        values: "",
    });
    const [productVariantDetails, setProductVariantDetails] = useState(null);
    const defaultSorted = [{ dataField: "id", order: "asc" }];
    state.auth.authToken = localStorage.getItem("access_token")

    useEffect(() => {
        setLoaderVisible(true);
        getList();
    }, []);

    const renderImg = (item) => (
        <img
            style={{ height: "3rem", width: "3rem" }}
            src={`${URLs.awsS3ImageURL}${item.image}`}
        />
    );

    const renderTableCell = text => {
        if (text.length > 12) return `${text.substring(0, 10)}..`
        else return `${text.substring(0, 12)}`
    }

    const getList = async () => {
        let res = await getDataApi(`${URLs.gearProductVariantURL}?offset=${pageOffset}&limit=10`, state.auth.authToken);
        if (res.status === 200) {
            let listArr = [];
            res.data.data.docs.map((item) => {
                listArr.push({
                    _id: item._id,
                    ProductId: item.ProductId,
                    name: renderTableCell(item.name),
                    image: renderImg(item),
                    ProductDescription: renderTableCell(item.ProductDescription),
                    ProductUniversalCode: renderTableCell(item.ProductUniversalCode),
                    values: renderTableCell(item.values),
                    ProductName: renderTableCell(item.ProductName),
                });
            });
            setListData(listArr);
            setLoaderVisible(false);
        } else {
            toastMsgError(res.message);
            setLoaderVisible(false);
        }
    };

    const renderActions = (_, row) => {
        return (
            <>
                <a
                    title="Edit product variant"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {
                        e.stopPropagation();
                        console.log(row)
                        setAddEditInitValues({
                            _id: row._id,
                            name: row.name,
                            image: row.image,
                            values: row.values,
                        });
                        setSelectedRow(row)
                        setCrudAction("EDIT");
                        setAddEditModalShow(true);
                        history.push("/gear/product/product-variant/edit");
                    }}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                    </span>
                </a>
                <a
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRow(row)
                        setDeleteModalShow(true);
                        setCrudAction("DELETE");
                        history.push("/gear/product/product-variant/delete");
                    }}
                    title="Delete product variant"
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                    </span>
                </a>
            </>
        );
    };

    // Table columns
    const columns = [
        {
            dataField: "_id",
            text: "ID",
            sort: true,
            hidden: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "image",
            text: "Image",
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "ProductName",
            text: "Product Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "values",
            text: "values",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "ProductUniversalCode",
            text: "Product UniversalCode",
        },
        {
            dataField: "ProductDescription",
            text: "Product Description",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: renderActions,
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];

    const addNewProductVariant = async (values) => {
        if (uploadedImgResURL === null) toastMsgWarn("Upload an image!");
        else {
            let item = {
                name: values.name,
                values: values.values,
                image: uploadedImgResURL,
                ProductId: productDetails._id
            };
            setLoaderVisible(true);
            let res = await postDataApi(
                URLs.gearProductVariantURL,
                state.auth.authToken,
                item
            );
            if (res.status === 200) {
                getList();
                toastMsgSuccess("Product Variant Successfully Added!");
                setLoaderVisible(false);
            } else {
                toastMsgError(res.message);
                setLoaderVisible(false);
            }
            setSelectedRow(null);
            setAddEditModalShow(false);
            setUploadedImgResURL(null);
            setUploadImg(null);
            setAddEditInitValues({ _id: "", name: "", image: "", values: "" })
            history.push("/gear/product/product-variant");
        }
    };

    const editProductVariant = async (values) => {
        const imgSrc = values.image.props.src;
        const index = imgSrc.search("fishfinderpost");
        const imgURL = imgSrc.slice(index, imgSrc.length);
        if (!imgURL && uploadedImgResURL === null) toastMsgWarn("Upload an image!");
        else {
            if (listData.length !== 0) {
                let item = {
                    id: values._id,
                    name: values.name,
                    values: values.values,
                    image: uploadedImgResURL === null ? imgURL : uploadedImgResURL,
                    ProductId: productDetails._id
                };
                setLoaderVisible(true);
                let res = await postDataApi(
                    URLs.gearProductVariantURL,
                    state.auth.authToken,
                    item
                );
                if (res.status === 200) {
                    getList();
                    toastMsgSuccess("Product Variant Successfully Edited!");
                    setLoaderVisible(false);
                } else {
                    toastMsgError(res.message);
                    setLoaderVisible(false);
                }
            }
            setSelectedRow(null);
            setAddEditModalShow(false);
            setUploadedImgResURL(null);
            setUploadImg(null);
            setAddEditInitValues({ _id: "", name: "", image: "", values: "", })
            history.push("/gear/product/product-variant");
        }
    };

    const deleteProductVariant = async () => {
        if (selectedRowForCrud) {
            setLoaderVisible(true);
            let res = await deleteDataApi(
                URLs.gearProductVariantURL + '/' + selectedRowForCrud._id,
                state.auth.authToken,
                { id: selectedRowForCrud._id }
            );
            // console.log(res)
            if (res.status === 200) {
                getList();
                toastMsgSuccess("Product Variant Successfully Deleted!");
                setLoaderVisible(false);
            } else {
                toastMsgError(res.message);
                setLoaderVisible(false);
            }
            setDeleteModalShow(false);
            setSelectedRow(null);
            history.push("/gear/product/product-variant");
        }
    };

    const goToNextPage = () => {
        setPageOffset(pageOffset + 10);
        setPage(page + 1);
    };

    const goToPrevPage = () => {
        if (pageOffset !== 0) {
            setPageOffset(pageOffset - 10);
            setPage(page - 1);
        }
    };

    useEffect(() => {
        getList();
    }, [pageOffset]);

    const goToFirstPage = () => {
        setPageOffset(0);
        setPage(1);
    };

    const uploadImage = async (imageFile) => {
        let form = new FormData();
        form.append("photo", imageFile);
        let res = await putDataApi(
            URLs.picUpload,
            state.auth.authToken,
            form
        );
        if (res.status === 200) {
            setUploadedImgResURL(res.data.data.images);
            // toastMsgSuccess("User Deleted Successfully!");
        } else {
            toastMsgError(res.message);
        }
    };

    const getProductVariantDetails = async (row) => {
        // console.log(row)
        let res = await getDataApi(`${URLs.gearProductVariantURL}/${row._id}`, state.auth.authToken, { id: row._id });
        // console.log(res)
        if (res.status === 200) {
            setProductVariantDetails(res.data.data)
        } else {
            toastMsgError(res.message);
        }
    };

    useEffect(() => setSelectedRow(selectedRowForCrud), [selectedRowForCrud])

    const uploadFile = async e => {
        let file = e.target.files[0]
        if (file) {
            setFileLoader(true)
            let form = new FormData();
            form.append("file", file);
            let res = await putDataApi(
                URLs.gearBulkUploadURL,
                state.auth.authToken,
                form
            );
            console.log(res)
            if (res.status === 200) {
                getList();
                toastMsgSuccess(res.data.message);
                setFileLoader(false)
            } else {
                setFileLoader(false)
                toastMsgError(res.message);
            }
        } else toastMsgWarn("Select a CSV file!");
    }

    return (
        productVariantDetails ?
            <ProductVariantDetails
                history={history}
                onBack={() => {
                    setProductVariantDetails(null)
                    history.push("/gear/product/product-variant");
                }}
                productVariantDetails={productVariantDetails}
            /> :
            <div className={`card card-custom ${className}`}>
                {/* begin::Header */}
                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Product Variant
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Product Variant list
                        </span>
                    </h3>
                    <div className='d-flex align-items-center'>
                        <Button variant="btn btn-dark mr-3" style={{ height: '2.9rem' }} onClick={onBack}>
                            Back
                        </Button>
                        <div className="card-toolbar ml-0 mr-3">
                            <a
                                download='gear_template.csv'
                                href={gearCsv}
                                className="btn btn-dark font-weight-bolder font-size-sm">
                                Download template
                            </a>
                        </div>
                        <div className="card-toolbar mr-5 ml-0">
                            {isFileLoaderShow && (
                                <div className='mr-2'>
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            )}
                            <input
                                type="file"
                                accept='.csv'
                                className='upload_file_btn'
                                onChange={uploadFile}
                            />
                        </div>
                        <div
                            onClick={() => {
                                setCrudAction("ADD");
                                setAddEditModalShow(true);
                                history.push("/gear/product/product-variant/new");
                            }}
                            className="card-toolbar"
                        >
                            <a className="btn btn-success font-weight-bolder font-size-sm">
                                Add Product Variant
                            </a>
                        </div>
                    </div>
                </div>
                {/* end::Header */}


                <div className="card-body py-0">
                    {isLoaderShow && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "15px 0",
                            }}
                        >
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                    <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        keyField="_id"
                        key="_id"
                        data={listData}
                        columns={columns}
                        hover={true}
                        defaultSorted={defaultSorted}
                        rowEvents={{
                            onClick: (e, row, rowIndex) => {
                                getProductVariantDetails(row)
                            },
                        }}
                    >
                        <PleaseWaitMessage entities={listData} />
                        <NoRecordsFoundMessage entities={listData} />
                    </BootstrapTable>
                    {listData.length !== 0 && (
                        <div
                            className="mb-5"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            {pageOffset !== 0 && (
                                <Button variant="light mr-3" onClick={goToPrevPage}>
                                    Previous
                                </Button>
                            )}
                            {listData.length === 10 ? (
                                <Button variant="light" onClick={goToNextPage}>
                                    Next
                                </Button>
                            ) : null}
                            <div style={badgeContStyle}>
                                {page > 1 ? (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="tooltip-disabled">Back to page 1</Tooltip>
                                        }
                                    >
                                        <Button
                                            onClick={goToFirstPage}
                                            variant="light"
                                            style={badgeStyle}
                                        >
                                            <ExpandLessRoundedIcon />
                                        </Button>
                                    </OverlayTrigger>
                                ) : null}
                                <Badge variant="light ml-3" style={badgeStyle}>
                                    Page - {page}
                                </Badge>
                            </div>
                        </div>
                    )}
                </div>

                {isAddEditModalShow && (
                    <AddEditProductVariant
                        isShowModal={isAddEditModalShow}
                        onHideModal={() => {
                            setAddEditModalShow(false);
                            setSelectedRow(null);
                            setUploadedImgResURL(null);
                            setUploadImg(null);
                            setAddEditInitValues({ _id: "", name: "", image: "", values: "" })
                            history.push("/gear/product/product-variant");
                        }}
                        initialValues={addEditInitValues}
                        onSubmitForm={(values) =>
                            crudAction === "ADD" ? addNewProductVariant(values) : editProductVariant(values)
                        }
                        setImg={(imgFile) => {
                            uploadImage(imgFile);
                            setUploadImg(imgFile);
                        }}
                        uploadedImg={uploadedImg}
                        isLoaderShow={isLoaderShow}
                    />
                )}
                {isDeleteModalShow && (
                    <DeleteModal
                        isDeleteModalShow={isDeleteModalShow}
                        onHide={() => {
                            setDeleteModalShow(false);
                            history.push("/gear/product/product-variant");
                        }}
                        onDelete={() => deleteProductVariant(selectedRowForCrud)}
                        headline='Delete Product Variant'
                        subHeading='Are you sure to permanently delete this product variant?'
                    />
                )}
                <ToastContainer />
            </div>
    );
}

