import { toast } from "react-toastify";

export const URLs = {
  awsS3ImageURL: "https://s3.ap-south-1.amazonaws.com/mobmaximetestbucket/",
  getUserDetailsURL: `${process.env.REACT_APP_API_URL}/user/details`,
  picUpload: `${process.env.REACT_APP_API_URL}/media/uploadpicture`,
  allUsersGetURL: `${process.env.REACT_APP_API_URL}/user/all-users`, //?offset=0&limit=10
  gearBulkUploadURL: `${process.env.REACT_APP_API_URL}/gear/upload`,
  gearCategoryURL: `${process.env.REACT_APP_API_URL}/gear/category`,
  gearCompanyURL: `${process.env.REACT_APP_API_URL}/gear/company`,
  allProductsGetURL: `${process.env.REACT_APP_API_URL}/gear/product/all`,
  gearProductURL: `${process.env.REACT_APP_API_URL}/gear/product`,
  gearProductVariantURL: `${process.env.REACT_APP_API_URL}/gear/productVariant`,
  baitURL: `${process.env.REACT_APP_API_URL}/bait`,
  deleteUserURL: `${process.env.REACT_APP_API_URL}/user/delete-user-account`,
  userDetailsURL: `${process.env.REACT_APP_API_URL}/user/user-details`,
  speciesURL: `${process.env.REACT_APP_API_URL}/species`,
  speciesDetailsURL: `${process.env.REACT_APP_API_URL}/species/details`,
  watersURL: `${process.env.REACT_APP_API_URL}/waters`,
  watersDetailsURL: `${process.env.REACT_APP_API_URL}/waters/details`,
  countryListURL: `${process.env.REACT_APP_API_URL}/list/country`,
  userPostListURL: `${process.env.REACT_APP_API_URL}/userPost/report-post-list`,
  userPostURL: `${process.env.REACT_APP_API_URL}/userPost/admin-post/`,
  // add new API
  aboutusPostURL: `${process.env.REACT_APP_API_URL}/static/about-us`,
  aboutusGetURL: `${process.env.REACT_APP_API_URL}/static/about-us`,

  termsPutURL: `${process.env.REACT_APP_API_URL}/static/terms`,
  termsGetURL: `${process.env.REACT_APP_API_URL}/static/terms`,

  privacyPutURL: `${process.env.REACT_APP_API_URL}/static/privacy`,
  privacyGetURL: `${process.env.REACT_APP_API_URL}/static/privacy`,

  copyrightPutURL: `${process.env.REACT_APP_API_URL}/static/copyright`,
  copyrightGetURL: `${process.env.REACT_APP_API_URL}/static/copyright`,

  feedbackGetURL: `${process.env.REACT_APP_API_URL}/user/feedback`,
};

export function toastMsgSuccess(msg) {
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
  });
}

export function toastMsgError(msg) {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
  });
}

export function toastMsgWarn(msg) {
  toast.warn(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
  });
}

export const tableCellCharValidate = (text) => {
  // if (text.length > 12) return `${text.substring(0, 10)}..`;
  // else return `${text.substring(0, 12)}`;
  if (text) {
    if (text.length > 12) {
      return `${text.substring(0, 10)}..`;
    } else {
      return `${text.substring(0, 12)}`;
    }
  } else {
    return ""; // Return an empty string if text is null or undefined
  }
};

export const badgeContStyle = {
  position: "absolute",
  right: "2.1rem",
  display: "flex",
  alignItems: "center",
};

export const badgeStyle = {
  height: "2rem",
  width: "5rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
