import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import ReactQuill from "react-quill"; // Assuming you have ReactQuill installed
import "react-quill/dist/quill.snow.css";
import { getDataApi, putDataApi } from "../../../utility/apiCalling";
import { URLs } from "../../../utility/constants";
import { useSelector } from "react-redux";
import { toastMsgSuccess, toastMsgError } from "../../../utility/constants";
import { ToastContainer } from "react-toastify";

export default function Copyright_policy() {
  // const [CopyrightText, setCopyrightText] = useState(""); // State to hold the content
  const state = useSelector((state) => state);
  const [pageOffset, setPageOffset] = useState(0);
  const [bodyText, setBodyText] = useState("");

  const fetchData = async () => {
    try {
      let res = await getDataApi(
        `${URLs.copyrightGetURL}?offset=${pageOffset}&limit=10`,
        state.auth.authToken
      );

      if (res.status === 200) {
        // console.log(res);
        // console.log(res.data.data.description);

        let res_data = res.data.data.description;
        setBodyText(res_data); // Assuming the response contains a bodyText field
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePost = async () => {
    try {
      let item = {
        description: bodyText,
      };

      let res = await putDataApi(
        URLs.copyrightPutURL,
        state.auth.authToken,
        item
      );
      console.log("Response:", res);

      if (res.status === 200) {
        fetchData();
        console.log("Data posted successfully");
        toastMsgSuccess("Successfully Added!");
      } else {
        console.log("Error message:", res.message);
        toastMsgError(res.message);
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // useEffect(() => {
  //   // Load bodyText from local storage when component mounts
  //   const storecopyrightBodyText = localStorage.getItem("CopyrightText");
  //   if (storecopyrightBodyText) {
  //     setCopyrightText(storecopyrightBodyText);
  //   }
  // }, []);

  // const handlePost = () => {
  //   // Logic to post the content (e.g., save it to a database)
  //   console.log("Posting:", CopyrightText);
  //   toastMsgSuccess("Successfully Added!");
  //   // Save bodyText to local storage
  //   localStorage.setItem("CopyrightText", CopyrightText);
  // };

  return (
    <div>
      <Card>
        <div>
          <ReactQuill
            theme="snow"
            placeholder="Write something..."
            modules={Copyright_policy.modules}
            formats={Copyright_policy.formats}
            value={bodyText}
            onChange={(text) => setBodyText(text)}
            // onChange={(text) => setCopyrightText(text)}
          />
        </div>

        <Button
          variant="success"
          style={{ width: "100px", marginTop: "50px", marginLeft: "45%" }}
          onClick={handlePost}
        >
          Submit
        </Button>
      </Card>
    </div>
  );
}

Copyright_policy.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
Copyright_policy.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];
